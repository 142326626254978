import Vue from 'vue';

import Router from 'vue-router';
import {
  REFRESH_TOKEN_TIME,
  TOKEN_EXPIRED_TIME
} from '@/env';

import Auth from '@/pages/Auth';
import ExecutionList from '@/pages/ExecutionList';
import HomePage from '@/pages/HomePage';
import HitGenerationExecuteView from
  '@/pages/HitGeneration/ExecuteView';
import HitGenerationResultView from
  '@/pages/HitGeneration/ResultView';
import TargetPredictionExecuteView from
  '@/pages/TargetPrediction/ExecuteView';
import TargetPredictionResultView from
  '@/pages/TargetPrediction/ResultView';
import PathwayPredictionExecuteView from
  '@/pages/PathwayPrediction/ExecuteView';
import PathwayPredictionResultView from
  '@/pages/PathwayPrediction/ResultView';
import LeadGenerationExecuteView from
  '@/pages/LeadGeneration/ExecuteView';
import LeadGenerationEMPIREResultView from
  '@/pages/LeadGeneration/EMPIRE/ResultView';
import LeadGenerationGx2MolResultView from
  '@/pages/LeadGeneration/Gx2Mol/ResultView';
import HitGenerationTransVAEBayesianResultView from
  '@/pages/HitGeneration/TransVAE/Bayesian/ResultView';
import HitGenerationTransVAELinearSearchResultView from
  '@/pages/HitGeneration/TransVAE/LinearSearch/ResultView';
import HitGenerationTransGANResultView from
  '@/pages/HitGeneration/TransGAN/ResultView';
import ReactionGenerationExecuteView from
  '@/pages/ReactionGeneration/ExecuteView';
import ReactionGenerationResultView from
  '@/pages/ReactionGeneration/ResultView';
import YamanishiMenu from '@/components/Yamanishi/Menu';
import GroupManagement from '@/pages/GroupManagement';

import NotFound from '@/pages/special/NotFound';
import ForbiddenPage from '@/pages/special/Forbidden';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'MLPlatform',
      components: {
        menu: YamanishiMenu,
        main: HomePage
      },
      meta: {
        title: 'Home',
        manualPage: 2
      }
    },
    {
      path: '/login',
      name: 'Auth',
      components: {
        main: Auth
      },
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/executions',
      name: 'ExecutionList',
      components: {
        menu: YamanishiMenu,
        main: ExecutionList
      },
      meta: {
        title: 'Execution History'
      }
    },
    {
      path: '/target_prediction/',
      name: 'TargetPredictionExecuteView',
      components: {
        menu: YamanishiMenu,
        main: TargetPredictionExecuteView
      },
      meta: {
        title: 'Target Prediction',
        manual: 'TargetPredictionManual.pdf'
      }
    },
    {
      path: '/target_prediction/:id',
      name: 'TargetPredictionResultView',
      components: {
        menu: YamanishiMenu,
        main: TargetPredictionResultView
      },
      meta: {
        title: 'Target Prediction',
        manual: 'TargetPredictionManual.pdf'
      }
    },
    {
      path: '/pathway_prediction/',
      name: 'PathwayPredictionExecuteView',
      components: {
        menu: YamanishiMenu,
        main: PathwayPredictionExecuteView
      },
      meta: {
        title: 'Pathway Prediction',
        manual: 'PathwayPredictionManual.pdf'
      }
    },
    {
      path: '/pathway_prediction/:id',
      name: 'PathwayPredictionResultView',
      components: {
        menu: YamanishiMenu,
        main: PathwayPredictionResultView
      },
      meta: {
        title: 'Pathway Prediction',
        manual: 'PathwayPredictionManual.pdf'
      }
    },
    {
      path: '/hit_generation/',
      name: 'HitGenerationExecuteView',
      components: {
        menu: YamanishiMenu,
        main: HitGenerationExecuteView
      },
      meta: {
        title: 'Hit Generation',
        manual: 'HitGenerationManual.pdf'
      }
    },
    {
      path: '/hit_generation/:id',
      name: 'HitGenerationResultView',
      components: {
        menu: YamanishiMenu,
        main: HitGenerationResultView
      },
      meta: {
        title: 'Hit Generation',
        manual: 'HitGenerationManual.pdf'
      }
    },
    {
      path: '/hit_generation/transvae/bayesian/:id',
      name: 'HitGenerationTransVAEBayesianResultView',
      components: {
        menu: YamanishiMenu,
        main: HitGenerationTransVAEBayesianResultView
      },
      meta: {
        title: 'Hit Generation by TransVAE with Bayesian optimization',
        manual: 'HitGenerationManual.pdf',
        manualPage: 26
      }
    },
    {
      path: '/hit_generation/transvae/linear_search/:id',
      name: 'HitGenerationTransVAELinearSearchResultView',
      components: {
        menu: YamanishiMenu,
        main: HitGenerationTransVAELinearSearchResultView
      },
      meta: {
        title: 'Hit Generation by TransVAE with linear search',
        manual: 'HitGenerationManual.pdf',
        manualPage: 39
      }
    },
    {
      path: '/hit_generation/transgan/:id',
      name: 'HitGenerationTransGANResultView',
      components: {
        menu: YamanishiMenu,
        main: HitGenerationTransGANResultView
      },
      meta: {
        title: 'Hit Generation by TransGAN',
        manual: 'HitGenerationManual.pdf',
        manualPage: 11
      }
    },
    {
      path: '/lead_generation/',
      name: 'LeadGenerationExecuteView',
      components: {
        menu: YamanishiMenu,
        main: LeadGenerationExecuteView
      },
      meta: {
        title: 'Lead Generation',
        manual: 'LeadGenerationManual.pdf'
      }
    },
    {
      path: '/lead_generation/empire/:id',
      name: 'LeadGenerationEMPIREResultView',
      components: {
        menu: YamanishiMenu,
        main: LeadGenerationEMPIREResultView
      },
      meta: {
        title: 'Lead Generation',
        manual: 'LeadGenerationManual.pdf',
        manualPage: 2
      }
    },
    {
      path: '/lead_generation/gx2mol/:id',
      name: 'LeadGenerationGx2MolResultView',
      components: {
        menu: YamanishiMenu,
        main: LeadGenerationGx2MolResultView
      },
      meta: {
        title: 'Lead Generation',
        manual: 'LeadGenerationManual.pdf',
        manualPage: 13
      }
    },
    {
      path: '/reaction_generation/',
      name: 'ReactionGenerationExecuteView',
      components: {
        menu: YamanishiMenu,
        main: ReactionGenerationExecuteView
      },
      meta: {
        title: 'Reaction Generation',
        manual: 'ReactionGenerationManual.pdf'
      }
    },
    {
      path: '/reaction_generation/:id',
      name: 'ReactionGenerationResultView',
      components: {
        menu: YamanishiMenu,
        main: ReactionGenerationResultView
      },
      meta: {
        title: 'Reaction Generation',
        manual: 'ReactionGenerationManual.pdf'
      }
    },
    {
      path: '/groups',
      name: 'GroupManagement',
      components: {
        menu: YamanishiMenu,
        main: GroupManagement
      },
      meta: {
        title: 'Group Management'
      }
    },
    {
      path: '*',
      name: 'NotFound',
      components: {
        menu: YamanishiMenu,
        main: NotFound
      },
      meta: {
        title: 'Page not found'
      }
    },
    {
      path: '*',
      name: 'Forbidden',
      components: {
        menu: YamanishiMenu,
        main: ForbiddenPage
      },
      meta: {
        title: 'Access denied'
      }
    }
  ]
});

router.beforeEach((toRoute, fromRoute, next) => {
  const session = Vue.prototype.$session;
  session.start();
  const tokenUsageTime = (Date.now() - session.get('tokenInitTime')) / 1000;
  if (tokenUsageTime > TOKEN_EXPIRED_TIME) {
    session.destroy();
  }
  if (tokenUsageTime > REFRESH_TOKEN_TIME &&
      tokenUsageTime < TOKEN_EXPIRED_TIME) {
    Vue.api.refreshToken(session.get('token'), function(res) {
      session.set('token', res.data.token);
      session.set('tokenInitTime', Date.now());
    }, function() {
      session.destroy();
    });
  }
  next();
});

router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.meta && toRoute.meta.title) {
    window.document.title = 'DAIIA Platform - ' + toRoute.meta.title;
  } else {
    window.document.title = 'DAIIA Platform';
  }
  next();
});

router.beforeEach((toRoute, fromRoute, next) => {
  const session = Vue.prototype.$session;
  session.start();
  const currentUser = session.get('user');
  if (toRoute.name === 'GroupManagement' &&
    currentUser &&
    currentUser.owned_groups.length === 0) {
    router.push({ name: 'Forbidden' });
  }
  next();
});

export default router;
