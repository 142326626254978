<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="executions"
    item-key="id"
    sort-by="created_at"
    sort-desc
    :items-per-page="itemsPerPage"
    fixed-header
    :height="tableHeight"
    hide-default-footer
    :search="search"
    @click:row="show"
    @pagination="onPaginationChange"
  >
    <template #[`item.action`]="{ item }">
      <CancelExecutionButton
        v-if="item.cancelable"
        :execution-id="Number(item.id)"
        @refresh="loadExecutions()"
      />
    </template>
    <template #top="{ pagination, options, updateOptions }">
      <v-data-footer
        :pagination="pagination"
        :options="options"
        :items-per-page-options="itemsPerPageOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        @update:options="updateOptions"
      >
        <template #prepend>
          <SearchField
            @input="search = $event"
          />
        </template>
      </v-data-footer>
    </template>
  </v-data-table>
</template>

<script>
import { ITEMS_PER_PAGE_OPTIONS } from '@/env';
import router from '../router';
import variables from '@/styles/variables.module.scss';
import SearchField from '@/components/SearchField';
import CancelExecutionButton from '@/components/CancelExecutionButton';

export default {
  name: 'ExecutionTable',
  components: {
    SearchField,
    CancelExecutionButton
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', value: 'id', filterable: false },
        { text: 'AI Name', value: 'name' },
        { text: 'Status', value: 'status', filterable: false },
        { text: 'Begin Datetime', value: 'begin_at', filterable: false },
        { text: 'End Datetime', value: 'end_at', filterable: false },
        { text: 'Executor', value: 'executor' },
        { text: 'Action', value: 'action' }
      ],
      executions: [],
      itemsPerPage: Number(localStorage.getItem('exec-ipp')) || 20,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS
    };
  },
  computed: {
    tableHeight: function() {
      if (variables.paginationRowHeight &&
          variables.containerPaddingX &&
          variables.appBarHeight) {
        return `calc(100vh - ${variables.paginationRowHeight}
        - ${variables.containerPaddingX}
        - ${variables.appBarHeight}
        - 1px)`; // buffer
      } else {
        console.error('Failed to get style from styles/variables.module.scss');
        return 'None';
      }
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadExecutions();
  },
  methods: {
    loadExecutions() {
      const self = this;
      this.api.loadExecutions(function(list) {
        for (const index in list) {
          list[index].name = list[index].module.name;
          list[index].id = String(list[index].id);
        }
        self.executions = list;
      }, function(error) {
        console.log(error);
      });
    },
    onPaginationChange(pagination) {
      if (pagination.itemsPerPage) {
        this.itemsPerPage = pagination.itemsPerPage;
        localStorage.setItem('exec-ipp', pagination.itemsPerPage);
      }
    },
    show(params) {
      const id = params.id;
      const name = params.module.name;
      let viewName = '';
      if (name === 'Target Prediction') {
        viewName = 'TargetPredictionResultView';
      } else if (name === 'Pathway Prediction') {
        viewName = 'PathwayPredictionResultView';
      } else if (name === 'Hit Generation' || name === 'Hit Generation by Gx2Mol') {
        viewName = 'HitGenerationResultView';
      } else if (name === 'Hit Generation by TransVAE with linear search') {
        viewName = 'HitGenerationTransVAELinearSearchResultView';
      } else if (name === 'Hit Generation by TransVAE with Bayesian optimization') {
        viewName = 'HitGenerationTransVAEBayesianResultView';
      } else if (name === 'Hit Generation by TransGAN') {
        viewName = 'HitGenerationTransGANResultView';
      } else if (name === 'Lead Generation by EMPIRE') {
        viewName = 'LeadGenerationEMPIREResultView';
      } else if (name === 'Lead Generation by Gx2Mol') {
        viewName = 'LeadGenerationGx2MolResultView';
      } else if (name === 'Reaction Generation by CasVAE' || name === 'Reaction Generation by bCasVAE') {
        viewName = 'ReactionGenerationResultView';
      }
      router.push({ name: viewName, params: { id: id } });
    }
  }
};
</script>

<style scoped>
::v-deep .v-data-table .v-data-table__wrapper {
  overflow: unset;
}
::v-deep .v-data-table-header th {
    position: sticky;
    top: 48px;
    z-index:20;
    background-color: white;
}
</style>
