<template>
  <v-container>
    <div>
      <v-form ref="form">
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="casVaeMethod"
              :items="casVaeMethods"
              item-value="value"
              item-text="text"
              label="Generation method"
              required
              :rules="[v => !!v || 'The prediction method is required']"
            >
              <template #append-outer>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-icon class="help-icon" v-bind="attrs" v-on="on"
                      >mdi-help-circle-outline</v-icon
                    >
                  </template>
                  <span v-html="methodHelp" />
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="casVaeMethod !== ''">
          <v-col cols="4">
            <v-select
              v-model="property"
              :items="properties"
              item-value="value"
              item-text="text"
              label="Desired property"
              required
              :rules="[v => !!v || 'The desired property is required']"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="epochs"
              label="Epoch"
              type="number"
              min="1"
              required
              :rules="[v => !!v || 'The type of the input is required']"
            />
          </v-col>
        </v-row>
        <v-row v-if="property=='pIC50'">
          <v-col cols="6">
            <ModuleInput
              :info="{
                description:
                  'A QSAR model file (Pickle format)',
                type: 'file',
              }"
              filled
              dense
              accept=".pickle"
              name="qsar_model"
              @setFile="onSetFile"
            />
          </v-col>
          <v-col class="d-flex align-center">
            <sample-download-button :filename="module.config.sample_file" />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="casVaeMethod === 'bcasvae'" cols="4">
            <v-text-field
              v-model="token"
              label="Amplify token"
              required
              :rules="[v => !!v || 'The type of the input is required']"
            />
          </v-col>
        </v-row>
        <ExecuteButton
          :disabled="!canSubmit"
          :validation-method="validate"
          :confirm-loading="loading"
          @confirm="onSubmit"
        />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import router from '@/router';
import ExecuteButton from '@/components/ExecuteButton.vue';
import ModuleInput from '@/components/ModuleInput';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';

export default {
  name: 'ReactionGenerationExecuteView',
  components: {
    ModuleInput,
    ExecuteButton,
    SampleDownloadButton
  },
  data() {
    return {
      id: this.$route.params.id,
      module: null,
      parameters: Object(),
      files: Object(),
      fileSelected: false,
      property: 'qed',
      epochs: 1,
      properties: [
        { text: 'QED', value: 'qed' },
        { text: 'logP', value: 'logp' },
        { text: 'Bioactivity', value: 'pIC50' }
      ],
      loading: false,
      input: {},
      casVaeMethod: this.$route.query.casVaeMethod || "", // default to empty string
      casVaeMethods: [
        { text: "bCasVAE", value: "bcasvae" },
        { text: "CasVAE", value: "casvae" },
      ],
      token: "",
    };
  },
  computed: {
    canSubmit: function () {
      if (this.property === "pIC50") {
        return this.fileSelected;
      } else if (this.casVaeMethod === "") {
        return false;
      } else if (this.casVaeMethod === "bcasvae" && this.token === "") {
        return false;
      } else {
        return true;
      }
    },
    moduleName: function () {
      return this.casVaeMethod === "bcasvae" ? "bCasVAE" : "CasVAE";
    },
    methodHelp: function () {
      switch (this.casVaeMethod) {
        case "":
          return "生成手法を選択してください。";
        case "bcasvae":
          return `xxxxxxxX<br>
                  xxxxすることにより、<br>
                  xxxxを出力します。`;
        case "casvae":
          return `xxxxxxxx<br>
                    xxxxすることにより、<br>
                    xxxxを出力します。`;
      }
      return "現在使用できません。";
    },
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    this.loadModule();
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    loadModule() {
      const self = this;
      this.api.getMLModuleByName(
        this.moduleName,
        function(module) {
          self.module = module;
          const config = JSON.parse(module.config);
          self.module.config = config;
        },
        function(error) {
          console.log(error);
        }
      );
    },
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.fileSelected = true;
        this.input[name] = file.name;
      } else {
        this.fileSelected = false;
      }
    },
    onSubmit(visibility) {
      this.loading = true;
      this.$session.start();
      const self = this;
      this.input = {
        ...this.input,
        'Desired property': this.properties.find(prop => prop.value === this.property).text,
        'Epoch': this.epochs
      };
      const executeOptions = {
        'property': this.property,
        'epoch': this.epochs,
        ...visibility,
      };
      if (this.casVaeMethod === 'bcasvae') {
        executeOptions.token = this.token
        this.input['Amplify token'] = this.token;
      }
      this.api.executeMLModule(
        { name: this.moduleName },
        executeOptions,
        self.files,
        function(response) {
          router.push({
            name: 'ReactionGenerationResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    }
  }
};
</script>
